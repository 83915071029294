import { Injectable } from '@angular/core';
import {HttpHeaders} from '@angular/common/http';

@Injectable()
export class DataProviderService {
    // Produccion
    private url = '../';

    // Develop
    //private url = 'http://127.0.0.1/';

    constructor() { }

    public getServiceUrl() {
        return this.url + 'axia-backend/public/index.php/api/';
    }

    public getRootUrl() {
        return this.url + 'axia-backend/public/';
    }

    public getServerUrl() {
        return this.url;
    }

    public authHeader() {
        const _headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'x-auth-token': localStorage.getItem('token'),
            // 'Authorization': btoa(localStorage.getItem('token')),
        });

        return {headers: _headers};
    }
}
