import { Injectable } from '@angular/core';
import {Usuario} from '../../models/usuario';

@Injectable()
export class Globals {
    usuario: Usuario;

    public setUsuario(usr) {
        this.usuario = usr;
    }

    public getUsuario() {
        return this.usuario;
    }

    public calculateDiffDates(inicio, final) {
        let respuesta = '';
        if (!inicio || !final) {
            return;
        }

        var date1 = new Date(inicio);
        var date2 = new Date(final);
        var diff = date2.getTime() - date1.getTime();
        var pasado = false;
        diff = diff / (1000 * 3600 * 24);
        if (diff < 0) {
            pasado = true;
        }
        diff = Math.round(diff);
        if (pasado && date2.getDay()-1 == date1.getDate()) {
            diff = -1;
        }

        let h = (new Date(date2.getTime())).toLocaleString().slice(11,16);
        if (h[4] == ':') {
            h = h.slice(0,4);
        }
        switch (diff) {
            case 0: {
                if (h == '00:00' || h == '3:00:') {
                    respuesta = 'Hoy';
                } else {
                    respuesta = 'A las ' + final.slice(11,16);
                }
                break;
            }
            case 1: {
                if (h == '00:00' || h == '3:00:') {
                    respuesta = 'Mañana';
                } else {
                    respuesta = 'Mañana a las ' + h;
                }
                break;
            }
            case -1: {
                if (h == '00:00' || h == '3:00:') {
                    respuesta = 'Ayer';
                } else {
                    respuesta = 'Ayer a las ' + h;
                }
                break;
            }
            default: {
                if (diff < 0) {
                    respuesta = 'Hace ';
                } else {
                    respuesta = 'En ';
                }
                respuesta += Math.abs(Math.ceil(diff)) + ' días';
                break;
            }
        }

        return respuesta;
    }

    public calcularHorasRestantes(inicio, final, short = false) {
        // Retorna la cantidad de horas desde/hasta un evento, siempre que nunca superen las 24 hs.
        let respuesta = '';
        let pasado = false;
        if (!inicio || !final) {
            return;
        }

        var date1 = new Date(inicio);
        var date2 = new Date(final);
        var diff = date2.getTime() - date1.getTime();
        var tiempo_restante;

        diff = diff / (1000 * 3600 * 24);
        if (diff >= -1 && diff <= 1) {
            if (diff < 0) {
                pasado = true;
                tiempo_restante = (new Date(date1.getTime() - date2.getTime())).toISOString().slice(11,16);
            } else {
                tiempo_restante = (new Date(date2.getTime() - date1.getTime())).toISOString().slice(11,16);
            }
        } else {
            return null;
        }

        if (short) {
            return tiempo_restante;
        }

        switch (pasado) {
            case true: {
                respuesta = 'Hace ' + tiempo_restante + ' hs';
                break;
            }
            default: {
                respuesta = 'En ' + tiempo_restante + ' hs';
                break;
            }
        }

        return respuesta;
    }

    public formatSocialMediaDate(dateStr: string): string {
        const now = new Date();
        const date = new Date(dateStr);

        const oneMinute = 60 * 1000;
        const oneHour = 60 * oneMinute;
        const oneDay = 24 * oneHour;
        const oneMonth = 30 * oneDay;  // Aproximación de un mes

        const diff = now.getTime() - date.getTime();

        // Formatear la hora
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const timeStr = `a las ${hours}:${minutes}`;

        if (diff < oneDay && now.getDate() === date.getDate()) {
            return `Hoy ${timeStr}`;
        } else if (diff < 2 * oneDay && now.getDate() - date.getDate() === 1) {
            return `Ayer ${timeStr}`;
        } else if (diff < oneMonth) {
            const days = Math.round(diff / oneDay);
            return `Hace ${days} días`;
        } else {
            const months = Math.round(diff / oneMonth);
            if (months <= 6) {
                return `Hace ${months} meses`;
            } else {
                // Formateo para fechas mayores a 6 meses
                return date.toLocaleDateString();
            }
        }
    }

    public fechaSinZonaHoraria(d) {
        var timeZoneDifference = (d.getTimezoneOffset() / 60) * -1; //convert to positive value.
        d.setTime(d.getTime() + (timeZoneDifference * 60) * 60 * 1000);
        d.toISOString();
        return d;
    }

    public esAdmin(): boolean {
        return localStorage.getItem('role') == 'ROLE_ADMIN' ||
            localStorage.getItem('role') == 'ROLE_DIRECCION';
    }
}
