import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {LayoutService} from "@app/core/services";
import {config} from "@app/core/smartadmin.config";

declare var $: any;

@Component({
  selector: 'sa-header',
  templateUrl: './header.component.html',
})

export class HeaderComponent implements OnInit {

  public store = {
    smartSkin: localStorage.getItem('sm-skin') || config.smartSkin,
    skin: config.skins.find((_skin) => {
      return _skin.name == (localStorage.getItem('sm-skin') || config.smartSkin)
    })
  };

  constructor(
      private router: Router,
  ) {
  }

  ngOnInit() {
    let skin = config.smartSkin;
    if (!localStorage.getItem('sm-skin')) {
      skin = localStorage.getItem('sm-skin');
    }
  }


  searchMobileActive = false;

  toggleSearchMobile(){
    this.searchMobileActive = !this.searchMobileActive;

    $('body').toggleClass('search-mobile', this.searchMobileActive);
  }

  onSubmit() {
    this.router.navigate(['/miscellaneous/search']);

  }
}
