import {Component, OnInit} from '@angular/core';
import {UserService} from "@app/core/services";


@Component({
  selector: 'sa-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit {
  public showParametrosGlobales: boolean;

  public rol: any;
  public loadingNavigationMenu: boolean;
  constructor (
      private us: UserService
  ) {
  }

  /*
  ROLES:
      ROLE_GTE_COMERCIAL
      ROLE_PRODUCCION
      ROLE_GTE_PRODUCCION
      ROLE_OP_COMERCIALES
      ROLE_ADMINISTRACION
      ROLE_DIRECCION
      ROLE_COMERCIAL
      ROLE_GTE_OPERACIONES
      ROLE_ADMIN
      ROLE_GTE_PLANTA
      ROLE_GTE_ADMIN
      ROLE_INGENIERIA
      ROLE_MARKETING
  */

  ngOnInit() {
    this.loadingNavigationMenu = true;
    this.showParametrosGlobales = false;
    this.rol = this.us.getRole()
        .then((res) => {
          this.rol = res;

          this.showParametrosGlobales = (this.rol == 'ROLE_ADMIN' || this.rol == 'ROLE_DIRECCION' || this.rol == 'ROLE_GTE_PLANTA'  || this.rol == 'ROLE_GTE_OPERACIONES' || this.rol == 'ROLE_GTE_COMERCIAL' || this.rol == 'ROLE_GTE_ADMIN');
          this.loadingNavigationMenu = false;
        });
  }

}
