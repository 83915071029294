import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "@env/environment";
import {DataProviderService} from "@app/data-provider.service";
import {NotificationService} from "@app/core/services";

@Injectable()
export class VersionCheckService {
    private currentHash = environment.version;

    constructor(
        private http: HttpClient,
        private dataProviderService: DataProviderService,
        private notificationService: NotificationService,
    ) {
    }

    /**
     * Checks in every set frequency the version of frontend application
     * @param url
     * @param {number} frequency - in milliseconds, defaults to 10 minutes
     */
    public initVersionCheck(url, frequency = 1000 * 60 * 5) {
        setInterval(() => {
            this.checkVersion(url);
        }, frequency);
        this.checkVersion(url);
    }

    /**
     * Will do the call and check if the hash has changed or not
     * @param url
     */
    private checkVersion(url) {
// timestamp these requests to invalidate caches

        this.http.get(this.dataProviderService.getServiceUrl() + 'environment', this.dataProviderService.authHeader() /* + '?t=' + new Date().getTime()*/)
            .subscribe(
                (response: any) => {
                    const hash = response;
                    const hashChanged = this.hasHashChanged(this.currentHash.version, hash.version);
                    // If new version, do something
                    if (hashChanged) {
                        //window.location.reload();
                        // ENTER YOUR CODE TO DO SOMETHING UPON VERSION CHANGE
                        // for an example: location.reload();
                        this.notificationService.smallBox({
                            title: "<span title='Esta versión: "+ this.currentHash.version + "'><b>Actualización disponible</b></span>",
                            content: "Hay una nueva versión del sistema disponible.<br>Versión actual: " + this.currentHash.version + ".<br> <p class='text-align-right'><a href-void class='btn btn-info btn-block btn-sm'><i class='fa fa-refresh fa-spin'></i>&nbsp;&nbsp;Actualizar a la versión "+ hash.version +"</a></p>",
                            color: "#333879",
                            icon: "fa fa-bell swing animated text-warning"
                        }, (ButtonPress, Value) => {
                            window.location.reload();
                        });
                    }
                    // store the new hash so we wouldn't trigger versionChange again
                    // only necessary in case you did not force refresh
                    this.currentHash = hash;
                },
                (err) => {
                    console.error(err, 'Error al verificar si existe nueva versión del sistema');
                }
            );
    }

    /**
     * Checks if hash has changed.
     * This file has the JS hash, if it is a different one than in the version.json
     * we are dealing with version change
     * @param currentHash
     * @param newHash
     * @returns {boolean}
     */
    private hasHashChanged(currentHash, newHash) {
        if (!currentHash) {
            return false;
        }
        return currentHash !== newHash;
    }
}
