import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {DataProviderService} from "@app/data-provider.service";
import {Actividad, Tarea, TareaWrapper} from "../../../models/agenda";
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/map';
import {delay, map} from "rxjs/operators";
import {Observable, throwError} from "rxjs";

@Injectable()
export class TareaService {
    private serviceUrl: string;
    constructor(
        public http: HttpClient,
        private dataProviderService: DataProviderService,
    ) { this.serviceUrl = this.dataProviderService.getServiceUrl() + 'agenda/tarea'; }


    public async getTareasPorTema(idTema: number): Promise<any> {
        return this.http.get(this.serviceUrl + '/tareasPorTema/' + idTema, this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError)
    }

    public async getTareasPorActividad(idActividad: number): Promise<any> {
        return this.http.get(this.serviceUrl + '/tareasPorActividad/' + idActividad, this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError)
    }


    public getElemento(id: number): Promise<any> {
        return this.http.get(this.serviceUrl + '/id/' + id, this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError)
    }

    // Dado una ID de tarea, obtiene todas las personas a las que les fue asignada la tarea
    public getAsignacionesDeTarea(id: number): Promise<any> {
        return this.http.get(this.serviceUrl + '/asignaciones/' + id, this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError)
    }

    // Obtiene todas las tareas ordenadas por tema. Adicionalmente se puede solicitar el estado de la tarea. Por defecto busca todos
    // PROXIMO A SER DEPRECADO!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    public getArrayTareasPorTema(idEstado = null, nombre_usuario = null): Observable<any> {
        let url = '';
        if (idEstado) {
            url += '/' + idEstado;
        }
        if (nombre_usuario) {
            url += '/' + nombre_usuario;
        }
        return this.http.get(this.serviceUrl + '/arrayTareasPorTema' + url, this.dataProviderService.authHeader())
            .map((res) => {
                return res;
            })
    }

    // Obtiene todas las tareas ordenadas por el criterio dado.
    // Criterios:
    // criteria: due  <- Clasifica las tareas por fecha de vencimiento
    public getArrayTareas(filters): any {
        let f = JSON.stringify(filters);
        return this.http.get(this.serviceUrl + '/arrayTareas?filters=' + f, this.dataProviderService.authHeader())
            .pipe(
                data => {return data},
            );
    }


    public editarElemento(elemento: Tarea): Promise<Tarea> {
        const elementoDTO = this.DTO(elemento);
        return this.http.patch(this.serviceUrl + '/' + elemento.id, JSON.stringify(elementoDTO), this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError);
    }

    // Editar elemento idem a la acción anterior, pero solo modifica desde la vista previa
    // Solo puede editar la fecha programada y el estado de la tarea
    public editarElementoWrapper(elemento: TareaWrapper): Promise<Tarea> {
        let idEstadoTarea = elemento.estado_tarea ? elemento.estado_tarea.id : null;
        let idClasificacion = elemento.clasificacion_tarea ? elemento.clasificacion_tarea.id : null;
        let idSubclasificacion = elemento.subclasificacion_tarea ? elemento.subclasificacion_tarea.id : null;
        let elementoDTO = {
            titulo: elemento.titulo,
            fecha: elemento.fecha_programada,
            idEstadoTarea,
            idClasificacion,
            idSubclasificacion,
            tags: elemento.tags
        }
        return this.http.patch(this.serviceUrl + '/' + elemento.id, JSON.stringify(elementoDTO), this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError);
    }

    /** Intercambiar el orden de dos tareas con clasificación personal
     * @param T1 Tarea de origen (la que fue movida)
     * @param T2 Tarea de destino (posición que asume la tarea de origen)
     */
    public cambiarOrdenTareas(T1: TareaWrapper, T2: TareaWrapper): Promise<Tarea> {
        if (!T1.clasificacion_personal || !T2.clasificacion_personal) {
            return;
        }
        let elementoDTO = {
            idTareaOrigen: T1.id,
            idTareaDestino: T2.id,
            ordenOrigen: T1.clasificacion_personal.orden,
            ordenDestino: T2.clasificacion_personal.orden,
        }
        return this.http.put(this.serviceUrl + '/intercambiarOrden', JSON.stringify(elementoDTO), this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError);
    }

    /** Intercambiar el orden de dos tareas con subclasificación
     * @param T1 Tarea de origen (la que fue movida)
     * @param T2 Tarea de destino (posición que asume la tarea de origen)
     */
    public cambiarOrdenTareasSubclasificacion(T1: TareaWrapper, T2: TareaWrapper): Promise<Tarea> {
        if (!T1.subclasificacion_tarea || !T2.subclasificacion_tarea) {
            return;
        }
        let elementoDTO = {
            idTareaOrigen: T1.id,
            idTareaDestino: T2.id,
            ordenOrigen: T1.orden,
            ordenDestino: T2.orden,
        }
        return this.http.put(this.serviceUrl + '/intercambiarOrden/subclasificacion', JSON.stringify(elementoDTO), this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError);
    }

    /** Establecer el orden de una tarea vinculada con clasificación personal
     * @param Tarea Tarea de origen
     */
    public establecerOrdenTareaClasificacionPersonal(Tarea: TareaWrapper): Promise<any> {
        if (!Tarea.clasificacion_personal) {
            return;
        }
        let elementoDTO = {
            idTarea: Tarea.id,
            orden: Tarea.clasificacion_personal.orden
        }
        return this.http.put(this.serviceUrl + '/setOrder/clasificacionPersonal', JSON.stringify(elementoDTO), this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError);
    }

    /** Establecer el orden de una tarea dentro de una subclasificación
     * @param Tarea Tarea de origen
     */
    public establecerOrdenTareaSubclasificacion(Tarea: TareaWrapper): Promise<any> {
        if (!Tarea.clasificacion_personal) {
            return;
        }
        let elementoDTO = {
            idTarea: Tarea.id,
            orden: Tarea.clasificacion_personal.orden
        }
        return this.http.put(this.serviceUrl + '/setOrder/subclasificacion', JSON.stringify(elementoDTO), this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError);
    }


    public asignarClasificacionPersonal(elemento: Tarea): Promise<Tarea> {
        const elementoDTO = this.DTO(elemento);
        return this.http.patch(this.serviceUrl + '/' + elemento.id + '/clasificar', JSON.stringify(elementoDTO), this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError);
    }

    // Idem a la función anterior pero para ser aplicada con TareaWrapper (vista previa de tarea)
    public asignarClasificacionPersonalWrapper(elemento: TareaWrapper): Promise<Tarea> {
        let idClasificacionPersonal = elemento.clasificacion_personal ? elemento.clasificacion_personal.id : null;
        let elementoDTO = {
            id: elemento.id,
            idClasificacionPersonal: idClasificacionPersonal
        }
        return this.http.patch(this.serviceUrl + '/' + elemento.id + '/clasificar', JSON.stringify(elementoDTO), this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError);
    }

    public agregarElemento(elemento: any): Promise<Tarea> {
        const elementoDTO = this.DTO(elemento);
        return this.http.post(this.serviceUrl, JSON.stringify(elementoDTO), this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError);
    }

    public DTO(elemento: any) {
        let detalle = null;
        if (elemento.detalle) {
            detalle = elemento.detalle;
        }
        let actividad = null;
        if (elemento.Actividad) {
            actividad = elemento.Actividad.id;
        }
        let fecha = null;
        if (elemento.FechaProgramada) {
            fecha = elemento.FechaProgramada;
        }
        let clienteContacto = null;
        if (elemento.ClienteContacto) {
            clienteContacto = elemento.ClienteContacto.id;
        }
        let proveedorContacto = null;
        if (elemento.ProveedorContacto) {
            proveedorContacto = elemento.ProveedorContacto.id;
        }
        let clasificacionTarea = null;
        if (elemento.Clasificacion) {
            clasificacionTarea = elemento.Clasificacion.id;
        }
        let subclasificacionTarea = null;
        if (elemento.Subclasificacion) {
            subclasificacionTarea = elemento.Subclasificacion.id;
        }
        let clasificacionPersonalTarea = null;
        if (elemento.ClasificacionPersonal) {
            clasificacionPersonalTarea = elemento.ClasificacionPersonal.id;
        }
        return {
            titulo: elemento.titulo,
            descripcion: detalle,
            fecha: fecha,
            idActividad: actividad,
            idEstadoTarea: elemento.EstadoTarea.id,
            idClienteContacto: clienteContacto,
            idClasificacion: clasificacionTarea,
            idSubclasificacion: subclasificacionTarea,
            idClasificacionPersonal: clasificacionPersonalTarea,
            idProveedorContacto: proveedorContacto,
            tags: elemento.tags,
            importancia: elemento.importancia
        };

    }

    public borrarElemento(id_tarea: number): Promise<any> {
        return this.http.delete(this.serviceUrl + '/' + id_tarea, this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError);
    }

    private handleError(error: HttpErrorResponse): any {
        if (error.status === 0) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('OCURRIÓ UN ERROR:', error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `RESPUESTA DEL BACKEND: ${error.status}, body was: `, error.error);
        }
        // Return an observable with a user-facing error message.
        return throwError(() => new Error('Something bad happened; please try again later.'));
    }

    public quickCompleteTask(id_tarea: number): Observable<any> {
        return this.http.get<any[]>(this.serviceUrl + '/quickComplete/' + id_tarea, this.dataProviderService.authHeader())
            .pipe(
                delay(10),
                map(data => data.length == 0 ? [{nombre: '', id_cc: -1}] : data)
            );
    }

    public misTareas(): Observable<any> {
        return this.http.get<any[]>(this.dataProviderService.getServiceUrl() + 'agenda/misTareas', this.dataProviderService.authHeader())
            .pipe(
                delay(10),
                map(data => data)
            );
    }

    public getTemaAsociado(idTarea: number): Observable<any> {
        return this.http.get<any>(this.serviceUrl + '/getTemaDeTarea/' + idTarea, this.dataProviderService.authHeader())
            .pipe(
                delay(10),
                map(data => data[0])
            );
    }

    public asignarTarea(idTarea, nombre_usuario) {
        const elementoDTO = {
            nombre_usuario: nombre_usuario,
            idTarea: idTarea,
        };
        return this.http.post(this.dataProviderService.getServiceUrl() + 'agenda/asignarTarea', JSON.stringify(elementoDTO), this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError);
    }

    public obtenerTareasCompartidas(): Observable<any> {
        return this.http.get<any>(this.dataProviderService.getServiceUrl() + 'agenda/tareas/tareasAsignadas', this.dataProviderService.authHeader())
            .pipe(
                map(data => data)
            );
    }

    // Obtiene un listado rapido de las tareas vigentes o activos por sector de planta
    // Solo obtiene id, titulo y descripcion de la tarea
    public getListadoTareasPorSectorDePlanta(idPlantaSector: number): Promise<any> {
        return this.http.get<any>(this.serviceUrl + '/tareasPendientesPorSectorPlanta/' + idPlantaSector, this.dataProviderService.authHeader())
            .toPromise().then(data => data);
    }

    public quitarTareaCompartida(Tarea: Tarea, nombre_usuario: string): Observable<any> {
        return this.http.delete<any>(this.dataProviderService.getServiceUrl() + 'agenda/asignacionTarea/tid/' + Tarea.id + '/uid/' + nombre_usuario, this.dataProviderService.authHeader())
            .pipe(
                map(data => data)
            );
    }

    // Si la tarea es finalizada por una actividad que ya existe, se la vincula
    public finalizarTareaConActividadExistente(Tarea: Tarea, Actividad: Actividad): Promise<any> {
        const elementoDTO = {
            idTarea: Tarea.id,
            idActividadFinalizadora: Actividad.id,
        };
        return this.http.post(this.dataProviderService.getServiceUrl() + 'agenda/tarea/tareaFinalzadaPorActividadExistente', JSON.stringify(elementoDTO), this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError);
    }
}

