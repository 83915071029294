import {Component, OnInit, Input} from '@angular/core';
import {Router} from "@angular/router";

@Component({
    selector: '[activitiesNotification]',
    templateUrl: './activities-notification.component.html',
})
export class ActivitiesNotificationComponent implements OnInit {

    @Input() item: any;

    constructor(
        private router: Router,
    ) {}

    ngOnInit() {
    }

    setClasses(){
        let classes = {
            'fa fa-fw fa-2x':true
        };
        classes[this.item.icon] = true;
        return classes
    }

    public redirigir() {
        let ruta;
        if (this.item['tipo'] == 'tarea') {
            this.router.navigate(["/agenda/tareas/1"], {
                queryParams: {
                    'idEstadoTarea': 1,
                    'id': this.item.tarea_id,
                }
            });

        } else if (this.item['tipo'] == 'tema') {
            this.router.navigate(['/agenda/tema/timeline/' + this.item.tema_id]);
        }

    }

}
