

import {NgModule, ModuleWithProviders} from "@angular/core";
import {CommonModule} from "@angular/common";
import {LoginInfoComponent} from "./login-info/login-info.component";
import {LogoutComponent} from "./logout/logout.component";
import {UsuarioService} from "@app/modulo-usuario/usuario/usuario.service";
import {LoginService} from "@app/auth/login/login.service";
import {DataProviderService} from "@app/data-provider.service";
import {Globals} from "@app/modulo-global/globals";

@NgModule({
  imports: [CommonModule],
  declarations: [LoginInfoComponent, LogoutComponent],
  exports: [LoginInfoComponent, LogoutComponent],
  providers: [UsuarioService, DataProviderService, LoginService, Globals]
})
export class UserModule {}
