import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DataProviderService} from "@app/data-provider.service";
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/map';
import {Observable} from "rxjs";
import {map, tap} from "rxjs/operators";
import {Chat} from "@app/modulo-agenda/tema/chat-tema/chat";

@Injectable()
export class ChatTemaService {
    private serviceUrl: string;
    constructor(
        public http: HttpClient,
        private dataProviderService: DataProviderService,
    ) { this.serviceUrl = this.dataProviderService.getServiceUrl() + 'agenda/chat/tema'; }

    public getChat(idTema): Observable<Chat[]> {
        return this.http.get(this.serviceUrl + '/' + idTema, this.dataProviderService.authHeader())
            .pipe(
                map(this.mapChat),
                tap(data => {
                    return data
                })
            );
    }

    public getChatCount(idTema: number): Promise<number> {
        return this.http.get(this.serviceUrl + '/' + idTema + '/count', this.dataProviderService.authHeader())
            .toPromise()
            .then((res: any) => {
                return res.count;
            });
    }

    public getUnreadChats(): Observable<{ tema_id: number, usuario_id: number, ultimo_acceso: string, fecha: string, nro_mensajes : number, titulo: string }[]> {
        return this.http.get<{ tema_id: number, usuario_id: number, ultimo_acceso: string, fecha: string, nro_mensajes: number, titulo: string }[]>(this.serviceUrl + '/unread/unread', this.dataProviderService.authHeader())
            .pipe(
                map((res: any) => res)
            );
    }

    private mapChat(chat): Array<Chat> {
        return chat
    }


    public send(idTema: number, message: string): Promise<Chat> {
        let elementoDTO = this.conformarDTO(idTema, message);
        return this.http.post(this.serviceUrl, JSON.stringify(elementoDTO), this.dataProviderService.authHeader())
            .toPromise()
            .then((res: Chat) => {
                return res;
            })
            .catch(this.handleError);
    }

    public conformarDTO(idTema: number, message: string) {
        return {
            message: message,
            idTema: idTema
        };
    }

    private handleError(error: any): Promise<any> {
        // console.error('An error occurred', error.error); // for demo purposes only
        return Promise.reject(error.error);
    }
}
